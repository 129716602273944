import { CssBaseline, Container, Grid, Paper, Typography, Button } from '@mui/material'
import React,{ useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BRAND_IMAGES } from '../../common/utils/constant'

const LandingPageThankyou = () => {

  const history = useHistory();

  const [countDown, setCountDown] = useState(10);

  useEffect(() => {
    if(countDown === 0){
      history.push('/')
    }

    if(countDown > 0){
      const interval = setInterval(() => {
        setCountDown(countDown -1)
      }, 1000);

      return () => clearInterval(interval);
    }
  },[countDown]);

  return (
    <React.Fragment>
        <CssBaseline />
        <Grid container justifyContent='center' alignItems='center' sx={{height: '100vh'}}>
          <Grid container component={Paper} elevation={2} direction='column' spacing={2} justifyContent='center' alignItems='center' sx={{width: 400, padding: '10px'}}>
            <Grid item>
              <img alt='check' src={BRAND_IMAGES['CHECK']} height={86} />
            </Grid>
            <Grid item>
              <Typography variant='h4' sx={{fontWeight: 'bold'}}>Thank You</Typography>
            </Grid>
            <Grid item>
              <Typography>Your message has be received and we</Typography>
            </Grid>
            <Grid item>
              <Typography>will be contacting you shortly</Typography>
            </Grid>
            <Grid item>
              <Typography variant='caption' color='textSecondary' >Redirecting {countDown}...</Typography>
            </Grid>
            <Grid item>
              <Button 
                onClick={() => history.push('/')}
                variant='contained'
                sx={{
                  padding: '5px 50px',
                  backgroundColor: '#191919',
                  "&:hover": {
                    backgroundColor: '#191919',
                    opacity: 0.9
                  }
                }} 
              >OK</Button>
            </Grid>
          </Grid>
        </Grid>
    </React.Fragment>
  )
}

export default LandingPageThankyou