import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Typography, Grid } from '@mui/material';
import {
  BRAND_IMAGES,
  BRAND_NAME,
  SERVICES,
} from '../../common/utils/constant';
import Inter from '../../assets/fonts/inter/Inter-VariableFont_slnt,wght.ttf';
import Blaka from '../../assets/fonts/Blaka/Blaka-Regular.ttf';
import { Link, useHistory } from 'react-router-dom';
import Form from './Form';
import { addLead } from '../../common/api';
import SnackBar from '../../components/alertSnackbar/SnackBar';
import Loader from '../../components/loader/Loader';
import CardServices from './CardServices';
import {
  theme,
  MainContainer,
  Header,
  Logo,
  DetailsContainer,
  Title,
  SubTitle,
  ContactButton,
  CardsAndFormContainer,
  TopCardContainer,
  CardContainer,
  PartnersContainer,
  PartnersTitle,
  SwiperContainer,
  Footer,
} from './Styles';
import ClientsList from '../clients/ClientsList';
import PartnersSwiper from './PartnersSwiper';

const Main = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: '',
    message: '',
  });

  const initialState = {
    contact_name: '',
    partner_name: '',
    phone: '',
    email_from: '',
    type: 'lead',
    service: '',
    name: '',
    crm_message: '',
  };

  const [data, setData] = useState(initialState);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await addLead(data);

      if (res?.result?.success) {
        setLoading(false);
        setData(initialState);
        setAlert({
          open: true,
          type: 'success',
          message: 'Details successfully submitted!',
        });
        history.push('/landing-page-thankyou');
      }

      if (res?.error) {
        throw res.error.message;
      }
    } catch (err) {
      setLoading(false);
      setAlert({
        open: true,
        type: 'error',
        message: err?.message ?? err,
      });
      console.log(err);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackBar alert={alert} setAlert={setAlert} />
      <Loader loading={loading} />

      <MainContainer
        container
        direction="column"
        bgImage={BRAND_IMAGES['LANDING_PAGE_BACKGROUND']}
      >
        <Header>
          <Link to="/">
            <Logo alt="logo" src={BRAND_IMAGES['LOGO_WHITE']} />
          </Link>
        </Header>
        <DetailsContainer
          container
          flex="row"
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Title>Are you looking for a trusted</Title>
          <Title>cargo logistics partner</Title>
          <Title>for your business?</Title>
          <SubTitle>{`It’s Fast! It’s Possible! ${BRAND_NAME}!`}</SubTitle>
          <Typography sx={{ padding: '10px 0' }}>
            Put your trust in us!
          </Typography>
          <Typography>We will help your business grow.</Typography>
          <ContactButton variant="contained">Contact Us</ContactButton>
        </DetailsContainer>
      </MainContainer>

      <CardsAndFormContainer container direction="column">
        <TopCardContainer
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <CardContainer
            container
            justifyContent="space-around"
            // alignItems="center"
            spacing={2}
          >
            {SERVICES.map(({ title, desc, icon }) => (
              <Grid item key={title} xs={12} md={4}>
                <CardServices title={title} desc={desc} icon={icon} />
              </Grid>
            ))}
          </CardContainer>
        </TopCardContainer>
        <Form
          data={data}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      </CardsAndFormContainer>

      <PartnersContainer
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
      >
        <PartnersTitle variant="h4">
          Trusted by Top Company Partners
        </PartnersTitle>
        <SwiperContainer container justifyContent="center" alignItems="center">
          <PartnersSwiper />
        </SwiperContainer>
      </PartnersContainer>

      <Footer container justifyContent="center" alignItems="center">
        <Typography>© 2022 ITSLOGIC FREIGHT All Rights Reserve</Typography>
      </Footer>
    </ThemeProvider>
  );
};

export default Main;
