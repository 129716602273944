import React from 'react'
import { Grid, TextField, Typography, Select, MenuItem, TextareaAutosize, Button } from '@material-ui/core';
import { onlyNumberKey } from '../../common/utils/helpers';
import { makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: '100vh',
    },
    topContainer: {
        height: '25vh',
        backgroundColor: '#191919',
        padding: '20px'
    },
    botContainer: {
        //height: '70vh',
        padding: '20px',
        //backgroundColor: 'red'
    },
    inputGroup: {
        width: '50vw',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        }
    },
    btnContainer: {
        width: '50vw',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        }
    },
    btn: {
        background: '#946F18',
        opacity: 1,
        color: 'white',
        width: '25vw',
        border: 'none',
        '&:hover':{
            background: '#946F18',
            opacity: 0.5,
            border: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '50vw',
        }
    }
}))

const Form = ({ data, handleChange, handleSubmit }) => {

    const classes = useStyles();
    const history = useHistory();

  return (
    <Grid className={classes.botContainer} item container justify='flex-start' alignItems='center' direction='column'>
        <Typography variant='h4' style={{color: '#191919', fontWeight: 'bold', marginBottom: '50px', textAlign: 'center'}} >Learn more by providing your info below</Typography>
        <form onSubmit={handleSubmit} autoComplete='off'>
            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12} >
                    <Typography style={{fontWeight: 'bold'}}>Name</Typography>
                </Grid>
                <Grid item xs={12} >
                    <TextField 
                        onChange={handleChange}
                        name='contact_name'
                        value={data?.contact_name}
                        required
                        type='text'
                        placeholder='Enter your name'
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Company</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        onChange={handleChange}
                        name='partner_name'
                        value={data?.partner_name}
                        required
                        type='text'
                        placeholder='Enter your company'
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Contact No.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        onChange={handleChange}
                        onKeyPress={e => onlyNumberKey(e)}
                        name='phone'
                        value={data?.phone}
                        required
                        type='text'
                        inputProps={{maxLength: 11}}
                        placeholder='Enter your contact no.'
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Email</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        onChange={handleChange}
                        name='email_from'
                        value={data?.email_from}
                        required
                        type='email'
                        placeholder='Enter your email'
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Service Type</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select-autowidth"
                        value={data?.service}
                        onChange={handleChange}
                        name='service'
                        label='Choose your type'
                        fullWidth
                        displayEmpty
                        required
                    >
                        <MenuItem value="" disabled selected>
                            <em>Choose your type</em>
                        </MenuItem>
                        <MenuItem value='Import'>Import</MenuItem>
                        <MenuItem value='International'>Export</MenuItem>
                        <MenuItem value='Domestic'>Domestic delivery</MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Subject</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        onChange={handleChange}
                        name='name'
                        value={data?.name}
                        required
                        type='text'
                        placeholder='Enter your subject'
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid container item spacing={2} className={classes.inputGroup} alignItems='start' direction='column'>
                <Grid item xs={12}>
                    <Typography style={{fontWeight: 'bold'}}>Message</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextareaAutosize 
                        style={{border: '1px #DCDCDC solid', borderRadius: '5px', padding: '10px', minHeight: '100px', maxHeight: '100px'}}
                        onChange={handleChange}
                        name='crm_message'
                        value={data?.crm_message}
                        required
                        rowsMax={3}
                        placeholder='Write your message'
                        fullWidth
                    />
                </Grid>
            </Grid>
            
            <Grid className={classes.btnContainer} container justify='center'>
                <Button type='submit' className={classes.btn} variant='contained'>Submit</Button>
            </Grid>

        </form>
    </Grid>
  )
}

export default Form