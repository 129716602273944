import React, { Component } from 'react';
import Isotope from 'isotope-layout';

import ServiceCard from './ServiceCard';

class ServicesContent extends Component {
  componentDidMount() {
    var grid = document.querySelector('.service-items');
    var iso = new Isotope(grid, {
      itemSelector: '.service-item',
      masonry: {
        itemSelector: '.service-item',
        transitionDuration: 0,
        stagger: 0,
      },
    });

    iso.layout();
  }

  render() {
    const services = [
      {
        id: 1,
        label: 'International (Export & Import)',
        link: '/air-freight',
        imageSrc: require('../../assets/img/placeholder/air.jpg'),
      },
      {
        id: 2,
        label: 'Domestic',
        link: '/domestic',
        imageSrc: require('../../assets/img/placeholder/logistics1.jpg'),
      },
      {
        id: 3,
        label: 'Value Added Services',
        link: '/value-added-services',
        imageSrc: require('../../assets/img/placeholder/sea.png'),
      },
      {
        id: 4,
        label: 'Contact Logistic / Partnership',
        link: '/contact-logistic-partnership',
        imageSrc: require('../../assets/img/placeholder/partnership.jpg'),
      },
    ];

    return (
      <div
        className={`service-items clearfix type-1 ${
          !this.props.isHomepage && 'pt-5'
        }`}
      >
        <div className="service-grid">
          {services.map((service) => (
            <ServiceCard
              key={service.id}
              label={service?.label}
              link={service?.link}
              imageSrc={service?.imageSrc}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default ServicesContent;

{
  /* { ServicesContentData && ServicesContentData.map( ( item, key ) => {
                    return (
                        <a key={ key } title={ item.title } className="service-item" href={ process.env.PUBLIC_URL + item.link }>
                            <div className="service-content">
                                <div className="service-content-sec">
                                    <div className="text">
                                        <h5 className="service-title">{ item.title }</h5>
                                        <p>{ item.description }</p>
                                    </div>

                                    <div className="button no-space">
                                        <p className="p-small bold transform-scale-h">Read more<i className="fas fas-space-l fa-long-arrow-alt-right align-middle"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src={ item.imgSrc } alt={ item.title } />
                                </div>
                            </div>

                            <div className="img-bg-color"></div>
                        </a>
                    );
                } ) } */
}
