import { styled, createTheme } from '@mui/material/styles';
import { Grid, Typography, Button, Card } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#946F18',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

export const MainContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'bgImage',
})(({ theme, bgImage }) => ({
  color: 'white',
  backgroundImage: `linear-gradient(rgba(26, 36, 42, 0.85), rgba(26, 36, 42, 0.85)), url(${bgImage})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  overflowX: 'hidden',
}));

export const Header = styled(Grid)(({ theme }) => ({
  zIndex: 10,
  padding: '20px',
}));

export const Logo = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  width: '141px',
  height: '115px',
  [theme.breakpoints.down('lg')]: {
    width: '92px',
    height: '74px',
  },
}));

export const DetailsContainer = styled(Grid)(({ theme }) => ({
  zIndex: 10,
  width: '60vw',
  alignSelf: 'center',
  textAlign: 'center',
  marginBottom: '150px',
  [theme.breakpoints.down('lg')]: {
    width: '90vw',
    marginTop: '70px',
  },
}));

export const Title = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'margin',
})(({ theme, margin }) => ({
  fontWeight: 'bold',
  fontSize: '62px',
  marginBottom: '10px',
  [theme.breakpoints.down('lg')]: {
    fontSize: '24px',
    padding: '5px 0',
  },
}));

export const SubTitle = styled(Typography)(({ theme }) => ({
  fontSize: '32px',
  fontWeight: 'bold',
  padding: '10px 0',
  [theme.breakpoints.down('lg')]: {
    fontSize: '20px',
    padding: '5px 0',
    fontWeight: '500',
  },
}));

export const ContactButton = styled(Button)(({ theme }) => ({
  borderRadius: '0',
  marginTop: '20px',
  padding: '10px 30px',
  textTransform: 'capitalize',
}));

export const CardsAndFormContainer = styled(Grid)(({ theme }) => ({}));

export const TopCardContainer = styled(Grid)(({ theme }) => ({
  position: 'relative',
  minHeight: 350,
  padding: '20px 0',
}));

export const CardContainer = styled(Grid)(({ theme }) => ({
  position: 'absolute',
  top: -120,
  width: '70vw',
  [theme.breakpoints.down('lg')]: {
    width: '90vw',
  },
  [theme.breakpoints.down('md')]: {
    position: 'initial',
  },
}));

export const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
  marginTop: '20px',
  height: '350px',
  [theme.breakpoints.down('md')]: {
    height: '100%',
  },
}));

export const PartnersContainer = styled(Grid)(({ theme }) => ({
  marginTop: '3rem',
  padding: '2rem 0',
}));

export const PartnersTitle = styled(Typography)(({ theme }) => ({
  width: '50vw',
  fontWeight: 'bold',
  textAlign: 'center',
}));

export const SwiperContainer = styled(Grid)(({ theme }) => ({
  width: '50vw',
  //padding: '20px 0',
  margin: '50px 0',
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
  },
}));

export const Footer = styled(Grid)(({ theme }) => ({
  padding: '2rem 0',
  borderTop: '1px grey solid',
  color: '#191919',
  marginTop: '3rem',
}));

export const Img = styled('img')(({ theme }) => ({
  height: '3rem',
  width: '6rem',
  objectFit: 'cover',
}));
