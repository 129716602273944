import React from 'react';

import GoogleMaps from '../google-maps/GoogleMaps';
import ContactsAccordion from './ContactsAccordion';

const ContactsContent = () => {
  return (
    <div id="maps" className="maps style-default">
      {/* <GoogleMaps /> */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2731.8022274805035!2d120.99127981524894!3d14.46447960330175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x97c2da39d4e0b384!2sAirmen&#39;s%20Village!5e0!3m2!1sen!2sph!4v1641945931213!5m2!1sen!2sph"
        width="2000"
        height="600"
        style={{ border: 0 }}
        allowFullScreen=""
        loading="lazy"
      ></iframe>
      <ContactsAccordion />
    </div>
  );
};

export default ContactsContent;
