import React, { useState } from 'react';
import { useHistory } from 'react-router';

const ServiceCard = (props) => {

    const {label, link, imageSrc} = props;
    const history = useHistory();

    const handleRedirect = () => {
        history.push(link);
    }
    
    const [onHover, setOnHover] = useState(false);

        return (
            <div onMouseEnter={()=> setOnHover(true)} onMouseLeave={()=> setOnHover(false)} className="service-card" style={{backgroundColor: `${onHover ? '#9A7F3E': '#fff'}`, transition: 'background-color 0.3s ease-out'}}>
                <div className="service-card__image" style={{backgroundImage: `url(${imageSrc})`, backgroundSize: 'cover', width: '100%', height: '100%', objectFit: 'cover'}}/>
                <div className="service-card__label">
                    <h3>{label}</h3>
                </div>
                <div onClick={()=> handleRedirect()} className="service-card__action">
                    <i className="fas fa-chevron-right"></i>
                </div>
            </div>
        );
};

export default ServiceCard;