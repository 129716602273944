import React, { useEffect } from 'react';
import Swiper from 'swiper';

const ClientsList = () => {

    useEffect(()=>{
        new Swiper ('.swiper-container.swiper-default', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            },
            slidesPerView: 2.5,
            breakpoints:{
                540:{
                    slidesPerView: 6.5
                }
            }
        });
    },[]);

    const imgSrc = [
        'assets/img/demo/1.png',
        'assets/img/demo/2.png',
        'assets/img/demo/3.png',
        'assets/img/demo/4.png',
        'assets/img/demo/5.png',
        'assets/img/demo/6.png',
        'assets/img/demo/7.png',
        'assets/img/demo/8.png',
        'assets/img/demo/9.png',
        'assets/img/demo/10.png',
        'assets/img/demo/11.png',
        'assets/img/demo/12.png',
        'assets/img/demo/13.png',
        'assets/img/demo/14.png',
        'assets/img/demo/15.png',
        'assets/img/demo/16.png',
        'assets/img/demo/17.png',
        'assets/img/demo/18.png',
        'assets/img/demo/19.png',
        'assets/img/demo/20.png',
        'assets/img/demo/21.png',
        'assets/img/demo/22.png',
    ]

    return (
        <div className="client-items clearfix">
            <div className="swiper-container swiper-default">
                    <div  className="swiper-wrapper">
                        {imgSrc.map((src)=> {
                            return (
                                <div key={src} className="swiper-slide">
                                <div className="swipebox">
                                    <div className="client-item">
                                        <div className="client-body">
                                            <img src={src} alt="Logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )
                        })}
                       
                        {/* <div key={2} className="swiper-slide">
                            <div className="swipebox">
                                <div className="client-item">
                                    <div className="client-body">
                                        <img src="assets/img/demo/2.png" alt="Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="swipebox">
                                <div className="client-item">
                                    <div className="client-body">
                                        <img src="assets/img/demo/3.png" alt="Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="swipebox">
                                <div className="client-item">
                                    <div className="client-body">
                                        <img src="assets/img/demo/4.png" alt="Logo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="swipebox">
                                <div className="client-item">
                                    <div className="client-body">
                                        <img src="assets/img/demo/7.png" alt="Logo" />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>

                    <div className="swiper-button-next swiper-button-white">
                        <i className="fas fa-chevron-right"></i>
                    </div>

                    <div className="swiper-button-prev swiper-button-white">
                        <i className="fas fa-chevron-left"></i>
                    </div>
            </div>
        </div>
    );
};

export default ClientsList;
