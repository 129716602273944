import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Privacy from './pages/privacy';
import About from './pages/About';
import Contacts from './pages/Contacts';
import Gallery from './pages/Gallery';
import GalleryInside from './pages/GalleryInside';
import Partners from './pages/Clients';
import News from './pages/News';
import NewsSinglePage from './pages/NewsSinglePage';
import Reviews from './pages/Reviews';
import SearchResults from './pages/SearchResults';
import Services from './pages/Services';
import ServiceInside from './pages/ServiceInside';
import page404 from './pages/404';
import WhyUs from './pages/WhyUs';
import UserInterface from './pages/UserInterface';
import ValueAddedServices from './pages/ValueAddedServices';
import Airfreight from './pages/Airfreight';
import ContactLogisticPartnership from './pages/ContactLogisticPartnership';
import LeadForm from './pages/Leadform';
import Track from './pages/Track';
import LandingPage from './pages/LandingPage';
import LeadFormThankyou from './components/form/LeadFormThankyou';
import LandingPageThankyou from './blocks/landing-page/LandingPageThankyou';
import Domestic from './pages/Domestic';
import ShippingRate from './pages/ShippingRate';

function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/'}`}
          component={Home}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/track'}`}
          component={Track}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/about-us'}`}
          component={About}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/contacts'}`}
          component={Contacts}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/gallery'}`}
          component={Gallery}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/gallery-inside'}`}
          component={GalleryInside}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/partners'}`}
          component={Partners}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/privacy'}`}
          component={Privacy}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/lead-form'}`}
          component={LeadForm}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/lead-form-thankyou'}`}
          component={LeadFormThankyou}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/landing-page'}`}
          component={LandingPage}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/landing-page-thankyou'}`}
          component={LandingPageThankyou}
        />
        {/* <Route exact path={ `${ process.env.PUBLIC_URL + '/news' }` } component={ News } /> */}
        {/* <Route exact path={ `${ process.env.PUBLIC_URL + '/news-single-page' }` } component={ NewsSinglePage } /> */}

        {/* <Route exact path={ `${ process.env.PUBLIC_URL + '/reviews' }` } component={ Reviews } /> */}

        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/search-results'}`}
          component={SearchResults}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/services'}`}
          component={Services}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/air-freight'}`}
          component={Airfreight}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/value-added-services'}`}
          component={ValueAddedServices}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/contact-logistic-partnership'}`}
          component={ContactLogisticPartnership}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/domestic'}`}
          component={Domestic}
        />

        <Route
          exact
          path={`${process.env.PUBLIC_URL + '/shipping-rate'}`}
          component={ShippingRate}
        />

        {/* <Route exact path={ `${ process.env.PUBLIC_URL + '/why-us' }` } component={ WhyUs } /> */}

        {/* <Route exact path={ `${ process.env.PUBLIC_URL + '/ui' }` } component={ UserInterface } /> */}

        <Route exact component={page404} />
      </Switch>
    </Router>
  );
}

export default App;
