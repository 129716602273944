import React from 'react';
import { COMMODITIES } from '../../common/utils/constant';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { MainContainer } from './Styles';
import CommoditySlide from './CommoditySlide';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Commodities = () => {
  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    width: 30,
    height: 30,
    color: '#9a7f3e',
    cursor: 'pointer',
  };

  return (
    <div className="wrapper">
      <div className="content">
        <div className="clearfix">
          <div className="col-12 text-center">
            <h3 style={{ marginBottom: '50px' }}>Our Commodities</h3>
            <Carousel
              renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                  <ArrowForwardIosIcon
                    onClick={onClickHandler}
                    style={{
                      ...arrowStyles,
                      right: 15,
                    }}
                  />
                )
              }
              renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                  <ArrowBackIosIcon
                    onClick={onClickHandler}
                    style={{
                      ...arrowStyles,
                      left: 15,
                    }}
                  />
                )
              }
              infiniteLoop={true}
              showThumbs={false}
              emulateTouch={true}
            >
              {COMMODITIES.map((comm, index) => (
                <MainContainer key={index}>
                  <CommoditySlide comm={comm} />
                </MainContainer>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Commodities;
