import React, { Component } from 'react';
import { Collapse } from '@material-ui/core';
import { Link } from 'react-router-dom';

class ModalMenuPrimary extends Component {
  constructor(props) {
    super();
    this.state = {
      open: false,
    };
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  render() {
    return (
      <nav className="menu-primary">
        <ul className="list-unstyled">
          <li className="nav-item">
            <Link to={process.env.PUBLIC_URL + '/'} title="Home">
              Home
            </Link>
          </li>
          {/* <li className={ "nav-item nav-item-has-children dropdown-hover " + ( window.location.pathname.includes( "/gallery" ) || window.location.pathname.includes( "/gallery-inside" ) ? "current-nav-item" : "" ) + ( this.state.over ? 'show' : '' ) }> */}

          <li
            className={
              'nav-item nav-item-has-children dropdown-hover ' +
              (window.location.pathname.includes('/services')
                ? 'current-nav-item'
                : '') +
              (this.state.open ? 'show' : '')
            }
          >
            <Link to={process.env.PUBLIC_URL + '/services'} title="Services">
              Services
            </Link>

            <span
              onClick={this.handleClick}
              className="dropdown-toggle dropdown-custom-icon"
            >
              <span className="dropdown-icon">
                <i className="fas fa-caret-down"></i>
              </span>
            </span>

            <Collapse in={this.state.open} timeout={400}>
              <ul className="dropdown-menu" style={{ display: 'block' }}>
                <li>
                  <Link
                    className="dropdown-item"
                    title="Air Freight"
                    to={process.env.PUBLIC_URL + '/air-freight'}
                  >
                    Air Freight
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    title="Value Added Services"
                    to={process.env.PUBLIC_URL + '/value-added-services'}
                  >
                    Value Added Services
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    title="Contact Logistic / Partnership"
                    to={
                      process.env.PUBLIC_URL + '/contact-logistic-partnership'
                    }
                  >
                    Contact Logistic / Partnership
                  </Link>
                </li>

                <li>
                  <Link
                    className="dropdown-item"
                    title="Domestic"
                    to={process.env.PUBLIC_URL + '/domestic'}
                  >
                    Domestic
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>

          <li
            className={
              'nav-item ' +
              (window.location.pathname.includes('/shipping-rate')
                ? 'current-nav-item'
                : '')
            }
          >
            <Link
              to={process.env.PUBLIC_URL + '/shipping-rate'}
              title="Shipping Rate"
            >
              Shipping Rate
            </Link>
          </li>

          <li
            className={
              'nav-item ' +
              (window.location.pathname.includes('/about-us')
                ? 'current-nav-item'
                : '')
            }
          >
            <Link to={process.env.PUBLIC_URL + '/about-us'} title="About us">
              About us
            </Link>
          </li>

          {/* <li className={ "nav-item " + ( window.location.pathname.includes( "/why-us" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/why-us" } title="Why us">Why us</Link>
                    </li> */}

          <li
            className={
              'nav-item ' +
              (window.location.pathname.includes('/gallery')
                ? 'current-nav-item'
                : '')
            }
          >
            <Link to={process.env.PUBLIC_URL + '/gallery'} title="Gallery">
              Gallery
            </Link>
          </li>

          {/* <li className={ "nav-item " + ( window.location.pathname.includes( "/ws" ) ? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/reviews" } title="Reviews">Reviews</Link>
                    </li> */}

          <li
            className={
              'nav-item ' +
              (window.location.pathname.includes('/partners')
                ? 'current-nav-item'
                : '')
            }
          >
            <Link to={process.env.PUBLIC_URL + '/partners'} title="Partners">
              Partners
            </Link>
          </li>

          {/* <li className={ "nav-item " + ( window.location.pathname.includes( "/news" ) || window.location.pathname.includes( "/news-single-page" )? "current-nav-item" : "" ) }>
                        <Link to={ process.env.PUBLIC_URL + "/news" } title="News">News</Link>
                    </li> */}

          <li
            className={
              'nav-item ' +
              (window.location.pathname.includes('/contacts')
                ? 'current-nav-item'
                : '')
            }
          >
            <Link to={process.env.PUBLIC_URL + '/contacts'} title="Contacts">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    );
  }
}

export default ModalMenuPrimary;
