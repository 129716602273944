import React from 'react';

import FooterMenu from '../footer/FooterMenu';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallIcon from '@mui/icons-material/Call';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

const FooterWidget = () => {
  return (
    <div className="footer-widgets">
      <div className="footer-widget-area d-flex flex-wrap">
        <div className="widget">
          <h5 className="widget-title">About us</h5>

          <p className="p-small">
            ITS LOGIC is currently establishing connections across the globe to
            boost our capabilities in handling complex freight forwarding
            requirements in delivering our commitment worldwide
          </p>
        </div>

        <div className="widget">
          <h5 className="widget-title">Menu</h5>

          <FooterMenu />
        </div>
      </div>

      <div className="contacts">
        <h5 className="widget-title">Contacts</h5>

        <div className="contact_info">
          <div>
            <p>
              <strong>Main Office</strong>
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnOutlinedIcon sx={{ mr: '10px' }} />
              <p>
                #2 Fokker's St., Airmen's Village, Pulang Lupa dos, Las Piñas
                City.
              </p>
            </div>
            <p style={{ marginTop: '20px' }}>
              <EmailOutlinedIcon sx={{ mr: '10px' }} /> info@itslogic.ph
            </p>
            <p style={{ marginTop: '20px' }}>
              {/* <CallIcon sx={{ mr: '10px' }} /> Tel. (02) 8658-3897 */}
              <CallIcon sx={{ mr: '10px' }} /> Tel. (02) 8876-1499
            </p>
          </div>

          <div>
            <p>
              <strong>Cebu Branch</strong>
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnOutlinedIcon sx={{ mr: '10px' }} />
              <p>SY Residence, Sangi Road, Brgy. Pajo, Lapu-Lapu City</p>
            </div>
            <p style={{ marginTop: '20px' }}>
              <EmailOutlinedIcon sx={{ mr: '10px' }} /> info@itslogic.ph
            </p>
            <p style={{ marginTop: '20px' }}>
              <CallIcon sx={{ mr: '10px' }} /> +63 949 3481919
            </p>
          </div>

          <div>
            <p>
              <strong>Iloilo Branch</strong>
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnOutlinedIcon sx={{ mr: '10px' }} />
              <p>
                Triple Alliance Realty & Sales Corp. Building. Valeria Ext. St.
                Iloilo City
              </p>
            </div>
            <p style={{ marginTop: '20px' }}>
              <EmailOutlinedIcon sx={{ mr: '10px' }} /> info@itslogic.ph
            </p>
            <p style={{ marginTop: '20px' }}>
              <CallIcon sx={{ mr: '10px' }} /> Tel. (033) 330-2676 / 09670174149
            </p>
          </div>

          <div>
            <p>
              <strong>Palawan Branch</strong>
            </p>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnOutlinedIcon sx={{ mr: '10px' }} />
              <p>
                Unit 3, MCU Bldg, Malvar Street Brgy. Mandaragat Puerto Princesa
                City, Palawan
              </p>
            </div>
            <p style={{ marginTop: '20px' }}>
              <EmailOutlinedIcon sx={{ mr: '10px' }} /> info@itslogic.ph
            </p>
            <p style={{ marginTop: '20px' }}>
              <CallIcon sx={{ mr: '10px' }} /> Tel. (04) 872-64895 / 09696408730
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterWidget;
