import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea, Grid } from '@mui/material';
import { StyledCard } from './Styles';

const CardServices = ({ title, icon, desc }) => {
  return (
    <StyledCard>
      <CardActionArea>
        <CardContent>
          <Grid
            container
            sx={{ padding: '10px 0' }}
            style={{ marginBottom: '25px' }}
            alignItems="center"
          >
            <Grid xs={3} item container justifyContent="center">
              <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid xs={9} item container justifyContent="center">
              <img
                alt={title}
                src={icon}
                width={150}
                height={100}
                style={{ objectFit: 'contain' }}
              />
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            color="text.secondary"
            style={{ padding: '10px' }}
          >
            {desc}
          </Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

export default CardServices;
