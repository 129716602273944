import React from 'react';

const LinkTitle = () => {
  return (
    <a
      title="About us"
      className="transform-scale-h"
      href={`${process.env.PUBLIC_URL + '/about-us'}`}
    >
      Who We Are
      <i className="fas fas-space-l fa-long-arrow-alt-right align-top"></i>
    </a>
  );
};

const TextTitle = () => {
  return <div title="About us">About us</div>;
};

const AboutUsTitle = (props) => {
  const isHomepage = props.isHomepage;

  if (isHomepage) {
    return <LinkTitle />;
  }
  return <TextTitle />;
};

const AboutUsPrimary = (props) => {
  return (
    <div className="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center mb-3">
      <div className="title">
        <h2>
          <AboutUsTitle isHomepage={props.isHomepage} />
        </h2>
      </div>

      <div className="text">
        <p>
          International Transport Solutions Logistics Freight INC was founded in
          November 2018 by a group of experienced industry experts with a single
          objective: give clients an <strong>excellent</strong> and reliable
          logistics solution.
          <br /> <br />
          In today’s world where everything is connected, technology can be used
          as a leverage for efficiency and effectivity. This is why ITSLogic is
          investing to build its own <strong>innovative</strong> enterprise
          system, that allows faster information processing and seamless
          operations from start to finish. This digital initiative is a proof of
          our <strong>commitment</strong> to help you, our clients and partners,
          achieve success no matter how small or big the business
          <br /> <br />
          ITSLogic Freight INC prides itself with values of Innovation,
          Integrity, Commitment and Excellence to deliver world class logistics
          services where clients and partners success is a top priority.
        </p>
      </div>

      {/* <div className="list-group list-group-horizontal spacer m-top-lg m-bottom-lg-media-xl style-default">
                <div className="list-group-item">
                    <h4 className="text-secondary">03</h4>
                    <p>Countries</p>
                </div>

                <div className="list-group-item">
                    <h4 className="text-secondary">25</h4>
                    <p>Offices</p>
                </div>

                <div className="list-group-item">
                    <h4 className="text-secondary">154</h4>
                    <p>Employees</p>
                </div>
            </div> */}
    </div>
  );
};

export default AboutUsPrimary;
