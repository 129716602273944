import React from 'react';
import { CommodityCard } from './Styles';
import { Typography } from '@mui/material';

const CommoditySlide = ({ comm }) => {
  return (
    <>
      {comm.map((item, index) => (
        <CommodityCard
          key={index}
          bgImage={item.bgImage}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h4"
            sx={{ fontWeight: 'bold' }}
            style={{ color: 'white', letterSpacing: '5px', padding: '5px' }}
          >
            {item.label}
          </Typography>
        </CommodityCard>
      ))}
    </>
  );
};

export default CommoditySlide;
