import React from 'react';

import WhyUsPdf from './WhyUsPdf';

const WhyUsContent = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="clearfix">
          <div className="row gutter-width-lg with-pb-lg style-default">
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">01</h4>
              <p className="text-white p-large bold">MISSION</p>
              <p className="text-white p-small">
                ITSLogic Freight’s mission is to provide world class Freight
                Forwarding and Logistics solutions to clients and partners with
                their success as our top priority.
              </p>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">02</h4>
              <p className="text-white p-large bold">VISION</p>
              <p className="text-white p-small">
                ITSLogic Freight’s Vision is to be the preferred domestic and
                international logistics solutions provider for its world class
                and reliable services.
              </p>
            </div>

            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
              <h4 className="text-secondary">03</h4>
              <p className="text-white p-large bold">CORE VALUES</p>
              <p className="text-white p-small">
                ITSLogic Freight is passionate in upholding our core values of
                Innovation, Integrity, Commitment and Excellence.
              </p>
            </div>

            {/* <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">04</h4>
                            <p className="text-primary p-large bold">Versatility</p>
                            <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <h4 className="text-secondary">05</h4>
                            <p className="text-primary p-large bold">Responsibility</p>
                            <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms.</p>
                        </div>

                        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12">
                            <WhyUsPdf />
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUsContent;
