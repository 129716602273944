import React from 'react';

const HeaderLogo = ( { logoColor } ) => {
    return (
        <a className="logo logo-primary transform-scale-h" title="Logo" href={ process.env.PUBLIC_URL + '/' }>
            <img width="70"
                 className={ 'logo-primary-' + ( logoColor === 'light' ? 'light' : 'dark' ) }
                 src={ 'assets/img/logo/airfreight-' + ( logoColor === 'light' ? 'white' : 'black' ) + ".svg" } alt="Logo"
            />
        </a>
    );
};

export default HeaderLogo;
