import React from 'react'

const Loader = ({loading}) => {
  return (
      <>
      {loading && (
        <div className="loading">
            <div className="wrapper h-100">
                <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="loading-content">
                        <div className="logo logo-primary">
                            <img className="animated zoomin" src="assets/img/logo/airfreight-black.svg" alt="Logo" />
                            <p style={{fontSize: '12px', textAlign: 'center', marginTop: '10px', fontWeight: 'bold'}}>Loading...</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      )}
      </>
  )
}

export default Loader