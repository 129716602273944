import React, { useEffect } from 'react';
import Swiper from 'swiper';
import { PARTNERS_LOGO } from '../../common/utils/constant';
import { Img } from './Styles';

const PartnersSwiper = () => {
  useEffect(() => {
    new Swiper('.swiper-container.swiper-default', {
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 2.5,
      breakpoints: {
        540: {
          slidesPerView: 4,
        },
      },
      loop: true,
    });
  }, []);

  return (
    // <div className="client-items clearfix">
    <div className="swiper-container swiper-default">
      <div className="swiper-wrapper">
        {PARTNERS_LOGO.map((logo, index) => {
          return (
            <div key={index} className="swiper-slide">
              <div className="swipebox">
                <div className="client-item">
                  <div className="client-body">
                    <Img src={logo} alt="Logo" />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="swiper-button-next swiper-button-white"
        style={{ height: '60px' }}
      >
        <i className="fas fa-chevron-right"></i>
      </div>

      <div
        className="swiper-button-prev swiper-button-white"
        style={{ height: '60px' }}
      >
        <i className="fas fa-chevron-left"></i>
      </div>
    </div>
    // </div>
  );
};

export default PartnersSwiper;
