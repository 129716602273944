import axios from "axios";

axios.interceptors.response.use(
    (res) => res,
    (err) => {
        if(err.response?.status === 401){
            throw err;
        }
        return Promise.reject(err);
    }
);

export const getUrl = (endpoint) => {
    const url = 
        endpoint.indexOf("http://") === 0 || endpoint.indexOf("https://") === 0
        ? endpoint
        : process.env.REACT_APP_ODOO_API_URL + endpoint
    return url;
}

const coreFunc = (verb, endpoint, config = {}, data = null) => {
    config.headers = {
        ...config.headers
    };

    if(data !== null){
        return axios[verb](getUrl(endpoint), data, config);
    }

    return axios[verb](getUrl(endpoint), config)
}

export const post = async(endpoint, data, headers={}) => 
    coreFunc(
        "post",
        endpoint,
        {headers: {"Content-Type": "application/json", ...headers}},
        JSON.stringify(data)
    );