import React from 'react';
import { useHistory } from 'react-router';
const AboutUsMedia = () => {
  const history = useHistory();
  // constructor() {
  //     super();
  //     this.state = {
  //         open: false
  //     };

  //     this.handleClick = this.handleClick.bind( this );
  //     this.close       = this.close.bind( this );
  // };

  // componentDidMount() {
  //     document.documentElement.classList.add( 'swipebox-no-touch' );
  // };

  // handleClick() {
  //     document.documentElement.classList.add( 'swipebox-html' );
  //     document.body.style.overflow = 'hidden';

  //     this.setState( { open: true } );
  // };

  // close() {
  //     this.setState( { open: false } );

  //     document.documentElement.classList.remove( 'swipebox-html' );
  //     document.body.style.overflow = 'auto';
  // };

  // render() {
  const url = 'https://www.youtube.com/embed/lSNmfy_KOJg';

  return (
    <div className="col-lg-6 col-md-12 col-sm-12 col-12 align-self-center">
      {/* <div className="embed">
                    <div className="embed-image-overlay">
                        <div className="embed-click swipebox" onClick={ this.handleClick }>
                            <div className="embed-center">
                                <div className="embed-play">
                                    <i className="fas fa-play"></i>
                                </div>

                                <p>HOW WE WORK</p>
                            </div>
                        </div>

                        <div id="swipebox-overlay" style={ { display: this.state.open ? "block" : "none" } }>
                            <div id="swipebox-container">
                                <div id="swipebox-slider" style={ { display: 'block' } }>
                                    <div className="slide">
                                        <div className="swipebox-video-container" style={ { maxWidth: '1140px' } }>
                                            <div className="swipebox-video">
                                                <iframe title="video" allowFullScreen frameBorder="0" src={ url }></iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id="swipebox-close" onClick={ this.close }></div>
                            </div>
                        </div>

                        <div className="embed-image img-blur">
                            <div className="img object-fit size-2">
                                <div className="object-fit-cover">
                                    <img src="assets/img/placeholder/915x591.jpg" alt="About us" />
                                </div>
                            </div>
                        </div>

                        <div className="embed-bg"></div>
                    </div>
                </div>
            </div> */}
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '10px',
          marginTop: '1rem',
        }}
      >
        <img
          style={{ width: '100%', height: '100%' }}
          src={require('../../assets/img/placeholder/air_freight.jpg')}
        />
        {/* <div> */}
        <img
          style={{ width: '100%', height: '100%' }}
          src={require('../../assets/img/placeholder/aboutImage.jpg')}
        />
        {/* <div style={{position: 'relative'}}>
               <img style={{width: '100%', height: 'auto'}} src={require('../../assets/img/placeholder/image-three.png')}/>
                <div style={{ width: '100%',backgroundColor: 'rgba(0,0,0,0.5)', position: 'absolute', bottom: 0,}}>
                    <p onClick={()=> history.push('/gallery')} style={{cursor: 'pointer',textAlign: 'right', padding: '0.5rem 1rem', color: 'white'}}>Gallery <i style={{paddingLeft: '0.2rem'}} className="fas fas-space-l fa-long-arrow-alt-right align-top"></i></p>
                    </div>
                </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default AboutUsMedia;
