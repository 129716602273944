import React, { Component } from "react";
import axios from "axios";

class ShortCodeModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: {
        name: "",
        lastName: "",
        email: "",
        company: "",
        phone: "",
        topic: "",
        message: "",
      },
      successMessage: "Message was sent successfully",
      warningMessage: "Fill up the form, please!",
      errorMessage: "Something went wrong. Try again later!",
      alertClass: "",
      responseMessage: "",
      alertTimeout: "",
      delay: 5000,
    };
  }

  submitForm = async (e) => {
    e.preventDefault();

    if (document.querySelector("#alert")) {
      document.querySelector("#alert").remove();
    }

    this.setState({ isSubmitting: true });

    const vals = {
      name: this.state.values.topic,
      partner_name: this.state.values.company,
      contact_name: `${this.state.values.name} ${this.state.values.lastName}`,
      email_from: this.state.values.email,
      phone: this.state.values.phone,
      crm_message: this.state.values.message,
      service: '',
      type: 'lead',
    };

    axios
      .post(`${process.env.REACT_APP_ODOO_API_URL}/leads`, vals, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            responseMessage: this.state.successMessage,
            values: {
              name: "",
              lastName: "",
              email: "",
              company: "",
              phone: "",
              topic: "",
              message: "",
            },
          });
          this.callAlert(this.state.successMessage, "success");
        }
      })
      .catch((error) => {
        this.callAlert(this.state.errorMessage, "error");
      });
  };

  removeAlert = () => {
    clearTimeout(this.state.alertTimeout);
    this.setState({
      alertTimeout: setTimeout(function () {
        var element = document.querySelector("#alert");
        element.classList.remove("fadeIn");
        element.classList.add("fadeOut");
        setTimeout(function () {
          element.remove();
        }, 900);
      }, this.state.delay),
    });
  };

  callAlert = (message, type) => {
    if (!document.querySelector("#alert")) {
      if (type === "success") {
        this.setState({ alertClass: "success" });
      }

      if (type === "error") {
        this.setState({ alertClass: "danger" });
      }

      if (type === "warning") {
        this.setState({ alertClass: "warning" });
      }

      var alert = '<div id="alert" class="animated fadeIn alert alert--shadow alert-' + this.state.alertClass + '">' + message + '</div>';

      var element = document.querySelector(".contact-form");

      element.insertAdjacentHTML("beforeend", alert);

      this.removeAlert();
    }
  };

  handleInputChange = (e) => {
    this.setState({
      values: {
        ...this.state.values,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {
    return (
      <form onSubmit={this.submitForm} method="post" action="form.php" className="contact-form">
        <div className="form-group">
          <label className="after" htmlFor="name">
            First name
          </label>
          <input value={this.state.values.name} onChange={this.handleInputChange} name="name" type="text" className="form-lg" id="name" required="required" placeholder="Juan" />
        </div>

        <div className="form-group">
          <label className="after" htmlFor="lastName">
            Last name
          </label>
          <input value={this.state.values.lastName} onChange={this.handleInputChange} name="lastName" type="text" className="form-lg" id="lastName" required="required" placeholder="Dela Cruz" />
        </div>

        <div className="form-group">
          <label className="after" htmlFor="email">
            Email
          </label>
          <input value={this.state.values.email} onChange={this.handleInputChange} name="email" type="email" className="form-lg" id="email" required="required" placeholder="juandelacruz@email.com" />
        </div>

        <div className="form-group">
          <label className="after" htmlFor="company">
            Company
          </label>
          <input value={this.state.values.company} onChange={this.handleInputChange} name="company" type="text" className="form-lg" id="company" required="required" placeholder="Juan DelaCruz Inc." />
        </div>

        <div className="form-group">
          <label className="after" htmlFor="phone">
            Phone no.
          </label>
          <input value={this.state.values.phone} onChange={this.handleInputChange} name="phone" type="text" className="form-lg" id="phone" required="required" placeholder="+912 456 7890" />
        </div>

        <div className="form-group">
          <label className="after" htmlFor="topic">
            Topic
          </label>
          <input value={this.state.values.topic} onChange={this.handleInputChange} name="topic" type="text" className="form-lg" id="topic" required="required" placeholder="Cargo Delivery" />
        </div>

        <div className="form-group">
          <label className="after" htmlFor="message">
            Message
          </label>
          <textarea value={this.state.values.message} onChange={this.handleInputChange} name="message" className="form-lg" id="message" required="required" placeholder="Hello..."></textarea>
        </div>

        <button type="submit" className="btn btn-secondary transform-scale-h">
          Submit
        </button>
      </form>
    );
  }
}

export default ShortCodeModalForm;
