import React from 'react';
import { useMediaQuery } from 'react-responsive'
import './PageTitle.scss'
import LanguageSelector from '../../components/button/LanguageSelector';
import { useState } from 'react';

const PageTitleHome = () => {

    const [trackingNumber, setTrackingNumber] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = `http://development-track.itslogic.ph/track/${trackingNumber}`
    }

    // constructor() {
    //     super();
    //     this.state = {
    //         lang: 'En',
    //     };

  //     this.onChangeValue = this.onChangeValue.bind( this );
  // };

  // onChangeValue( event ) {
  //     this.setState( {
  //         lang: event.target.title,
  //     } );
  // };

    // render() {
        const isLargeScreen = useMediaQuery({ minWidth: '1800px' });
        const isMobile = useMediaQuery({maxWidth: '540px'});
        return (
            <>
            <section id="page-title" className="block position-relative" style={ { backgroundImage: `url( ${ require( '../../assets/img/placeholder/background-image-home.jpg' ) } )`} }>
                <div className="wrapper h-100"> 
                        
                        <div className={`d-flex  position-relative h-100 ${isMobile ? 'justify-content-center' : 'justify-content-between'}`}>
                        <div className="align-self-center"> 
                            <div className="title">
                                <h1 className="title__headerOne">ITSLOGIC</h1>
                                <h1 className="title__headerTwo">FREIGHT</h1>
                            </div> 

              {/* <p className="spacer p-top-lg mb-0">Global logistics and transportation services via sea, land and air.<br/>We will protect you from risk and guarantee liability.</p> */}
            </div>

            {/* <div className="lang-position">
                            <nav className="languages">
                                <ul className="nav" onClick={ this.onChangeValue }>
                                    <LanguageSelector title="En" className={ this.state.lang === 'En' ? 'current-lang' : '' } />
                                    <LanguageSelector title="Ru" className={ this.state.lang === 'Ru' ? 'current-lang' : '' } />
                                    <LanguageSelector title="Lt" className={ this.state.lang === 'Lt' ? 'current-lang' : '' } />
                                </ul>
                            </nav>
                        </div> */}
                    </div>
                </div>
                {!isMobile &&
                <div class="custom-shape-divider-bottom-1617209220">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                </svg>
                </div>
                }
                {/* <div className="page-title-bg-color"></div> */}

                
            </section>
            <div style={{
                    position:'absolute', 
                    top: 0,
                    backgroundImage: `url( ${ require( '../../assets/img/placeholder/background-image-home-overlay.png' ) } )`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    height: '100vh',
                    minHeight: '550px',
                    maxHeight: '860px',
                    width: '100%',
                    zIndex: 50,
                    }}
                    
                    className='track'>  
                    {/* <div className='justify-content py-5'>
                        <form onSubmit={handleSubmit}>    
                        <div style={{ position:'relative'}} >      
                            <input type="text" required onChange={(e) => setTrackingNumber(e.target.value)}/> 
                            <label>Tracking ID</label> 
                            
                            <div className='m-2'>
                                <button type='submit'>Track Now</button> 
                            </div>
                        </div> 
                        </form>
                        
                    </div>     */}
            </div> 
            </>
        );
    };

export default PageTitleHome;
