import { Button } from '@mui/material';
import React from 'react';

const EstimateCostButton = ({ label, onClick }) => {
  return (
    <Button
      type="submit"
      variant="contained"
      sx={{
        backgroundColor: '#333333',
        '&:hover': { backgroundColor: 'black' },
      }}
      onClick={onClick}
    >
      <span style={{ padding: '5px' }}>{label}</span>
    </Button>
  );
};

export default EstimateCostButton;
