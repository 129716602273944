import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
import { Collapse } from '@material-ui/core';

const ModalMenuHome = () => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const modalOff = () => {
    let e = document.getElementById('close-modal');

    if (e) {
      e.click();
    }
  };

  return (
    <nav className="menu-primary">
      <ul className="list-unstyled">
        <li
          className={
            'nav-item nav-item-has-children dropdown-child-click-effect ' +
            (open ? 'show' : '')
          }
        >
          <Link
            to="services"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Services"
            href="#"
          >
            Services
          </Link>

          <span
            onClick={handleClick}
            className="dropdown-toggle dropdown-custom-icon"
            style={{ color: '#414258' }}
          >
            <span className="dropdown-icon">
              <i className="fas fa-caret-down"></i>
            </span>
          </span>

          <Collapse in={open} timeout={400}>
            <ul className="dropdown-menu" style={{ display: 'block' }}>
              <li>
                <Link
                  className="dropdown-item"
                  title="Air Freight"
                  to={process.env.PUBLIC_URL + '/air-freight'}
                >
                  Air Freight
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  title="Value Added Services"
                  to={process.env.PUBLIC_URL + '/value-added-services'}
                >
                  Value Added Services
                </Link>
              </li>

              <li>
                <Link
                  className="dropdown-item"
                  title="Contact Logistic / Partnership"
                  to={process.env.PUBLIC_URL + '/contact-logistic-partnership'}
                >
                  Contact Logistic / Partnership
                </Link>
              </li>

              <li>
                <a
                  className="dropdown-item"
                  title="Domestic"
                  href={process.env.PUBLIC_URL + '/domestic'}
                >
                  Domestic
                </a>
              </li>
            </ul>
          </Collapse>
        </li>

        <li className="nav-item">
          <RouterLink to="/shipping-rate">Shipping Rate</RouterLink>
        </li>

        <li className="nav-item">
          <Link
            to="about-us"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="About us"
            href="#"
          >
            About us
          </Link>
        </li>

        {/* <li className="nav-item">
                    <Link to="why-us" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="Why us" href="#">Why us</Link>
                </li> */}

        <li className={'nav-item'}>
          <Link
            to="gallery"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Gallery"
            href="#"
          >
            Gallery
          </Link>
        </li>

        {/* <li className="nav-item">
                    <Link to="reviews" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="Reviews" href="#">Reviews</Link>
                </li> */}

        <li className="nav-item">
          <Link
            to="partners"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Partners"
            href="#"
          >
            Partners
          </Link>
        </li>

        {/* <li className="nav-item">
                    <Link to="news" spy={ true } smooth={ true } duration={ 300 } onClick={ modalOff } title="News" href="#">News</Link>
                </li> */}

        <li className="nav-item">
          <Link
            to="contact"
            spy={true}
            smooth={true}
            duration={300}
            onClick={modalOff}
            title="Contact"
            href="#"
          >
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default ModalMenuHome;
