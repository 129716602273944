export const onlyNumberKey = (evt) => {
  const ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    return evt.preventDefault();
  }
  return true;
};

export const calculateExcessWeight = (ratePerKilo, weight) => {
  if (weight > 500) {
    return weight * (ratePerKilo - 15);
  } else if (weight > 100 && weight <= 500) {
    return weight * (ratePerKilo - 10);
  } else if (weight > 10 && weight <= 100) {
    return weight * (ratePerKilo - 5);
  } else if (weight > 5 && weight <= 10) {
    return weight * ratePerKilo;
  } else {
    return 0;
  }
};

export const insertCommas = (num) => {
  let parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};
