import React from "react";


const Privacy = () => {
    return (<div>
        <div className="privacy">
        <h2>Privacy Policy</h2>
        <p>We at ITSLOGIC FREIGHT INC. take your privacy seriously. 
            And We always want to make sure that Your privacy and the Personal 
            Information and other data You share with Us is absolutely safe. 
            That’s why we take every measure to protect and manage it.</p>
        
        <p>Capitalized terms used herein, where defined in the General Terms of Service, 
            shall have the same meaning and definition as indicated therein</p>

        <p>“Policy” as used in this Annex shall refer to this Annex.</p>

        <p><b>Service Description</b>. We collect different kinds of data to allow Us to provide 
        Our Services to You and ensure that the integrity of Our Service is maintained and that no 
        harmful or illegal activities transpire in the use of Our Services.</p>

        <p><b>Personal Information Collected and Processed</b>. We collect the following categories of data: Personal Data – These are data that allow Us to identify You as a person using Our Services. This shall</p>

        <p>include:</p>

        <p>Full Name</p>

        <p>Address</p>

        <p>Email Address</p>

        <p>Mobile Number</p>

        <p>Bank Account</p>

        <p>Social media username and handle</p>

        <p>Social media posts that are public</p>

        <p>Unique Identifiers – These are data that allows Us to identify You based on the browser, application, and device You are using to access Our Services.</p>

        <p>Internet Protocol Address (IP Address)</p>

        <p>International Mobile Equipment Identifier (IMEI)</p>

        <p>Data We Collect As You Use Our Services – These are the data We collect as You use Our Services: Content You upload in Our Services to perform Your Transactions</p>

        <p>Internet Browser and Browser Settings Data</p>

        <p>Operating System Data</p>

        <p>Mobile Network Information</p>

        <p>Geolocational Data</p>
                
        <p>Data We Collect Based On Your Activity – These are data We collect based on Your activities in Our Service:</p>

        <p>Other accounts You associate Your bank account with Frequency and volume of transactions</p>
                
        <p>Data We Collect Outside Our Service – In some cases, We work with government agencies and the private sector to collect data about You to prevent fraud and abuse in Our Services and conduct investigations.</p>
                            
        <p>Method of Collecting Data</p>

        <p>Personal Data</p>
                            
        <p>We receive Your Personal Information and of other individuals, including other users of the Services, the Buyers, and Fulfillment Partners that engage Our Services through Our registration procedure and other aspects of Our Services.</p>
                            
        <p>ITSLOGIC FREIGHT INC. may also contact You by email or phone call, to verify the information You provided to facilitate the use of the Services, to facilitate promos, offers, payment process and other requests. By providing Your Personal Information directly to us, you shall be deemed to have consented to the terms of this Policy, including the purposes set out in this Policy for which We collect, use and disclose your Personal Information.</p>
                            
        <p>Unique Identifiers, Data We Collect As You Use Our Services, and Data We Collect Based on Your Activity ITSLOGIC FREIGHT INC. collects these as You use Our Services</p>

        <p>Data We Collect Outside Our Services</p>
                            
        <p>By using Our Services, you consent to allowing Us to endorse the data You provided and We collected to government agencies, law enforcement agencies, and private organisations to profile and verify You in the event of an investigation for fraudulent and or irregular activities.</p>



        <p>You also consent to us sharing the data to other users of the Service, the Buyers, and our Fulfillment Partners in order to assist in investigations, prevent fraudulent activities, and maintain the integrity of Our Services.</p>
                            
        <p>Where You have provided Us with Personal Information of individuals other than Yourself, You warrant that you have obtained their consent for the disclosure and the processing of their Personal Information (pursuant to this Policy), in accordance with the Data Privacy Act of 2012 (“DPA”) and that any Personal Information provided by You is accurate and complete to facilitate the Services, such as, but not limited to, delivery of Items, investigation of cases, prevention of fraudulent activities.</p>
                            
        <p>Methods of Processing. Your Personal Information may be processed both by way of computer media and on paper, in compliance with the rules in relation to personal data protection.</p>

        <p>Purposes. The collected Personal Data from You will be used, and processed by Us and the third party service providers We partner with for the following reasons:</p>
                            
        <p>Account maintenance and verification Process transactions</p>
        <p>Resolving complaints and disputes Maintain a standard of merchant quality Feedbacks</p>
                            
        <p>Facilitation and sending of Promotions</p>
                            
        <p>Establish, exercise or defend legal claims</p>
                            
        <p>Facilitation of disbursal of payments or refunds</p>

        <p>Accounting, risk management, compliance and record keeping purposes to access the platform</p>
                            
        <p>Analysis and survey for the improvement of Our Services</p>
                            
        <p>Delivery of ads to You which are related to Our Services which may be of interest to You on the Site, other websites, apps, or online platforms</p>
                            
        <p>Conduct of market studies and market research</p>

        <p>Quality and Security Monitoring</p>

        <p>Provision of Customer Care and customer service</p>

        <p>Prevent, detect, and investigate crime, including fraud and analyzing and managing commercial risks Fulfillment of any other purposes directly related to the above-stated purposes</p>

        <p>We will not process your personal information in ways incompatible with the above-stated purposes.</p>
                
        <p>Data Retention. Your Personal Information will be retained or stored for as long as the purposes for which they are being processed have not been satisfied, as long as You are using Our Services, and as necessary to comply with legal obligations, resolve disputes and claims and enforce Our Terms.</p>
                            
        <p>Transfers and Disclosures. We take all reasonable steps to protect your Personal Information against unauthorized disclosure. We will not sell, rent, share, trade, or disclose any of Your Personal Data to any other party without Your prior written consent, with the exception of personnel or entities within Our organization and any third-party service providers which We engaged, whose services necessarily require the processing of your Personal Information. We may disclose Personal Information to the following:</p>

        <p>Disclosures to ITSLOGIC FREIGHT INC. employees and personnel – to provide and fulfill Our Services and obligations to You</p>
        
        <p>Disclosures to payment partners such as payment gateways, payment aggregators, payment system operators, banks, money services businesses and other financial institutions – to fulfill Our Services and obligations to You</p>
                        
        <p>Disclosure to our contractors and service providers including but not limited to logistics partners – to provide operational services and support and to assist Us in the fulfillment and performance of Our Services and obligations to You</p>
                        
        <p>Disclosures to Our auditors, bookkeepers, and lawyers</p>
                        
        <p>Disclosures to government agencies, law enforcement agencies, or relevant regulatory body in compliance with applicable laws</p>
                        
        <p>Disclosures to other users and Buyers in order to investigate complaints, prevent fraud, and maintain the integrity and safety of the Services</p>

        <p>Disclosures to brands, advertisers, retailers, and service providers for the delivery of advertisements to You of products and services which may be of interest to You</p>
                            
        <p>Disclosures to stockholders, business partners, potential investors, or target companies – to discuss potential merger and acquisition deals</p>

        <p>Disclosures to any other party whom You have provided your consent for such disclosure.</p>
                            
        <p>ITSLOGIC FREIGHT INC. implements strict standards of security across Our operations to prevent any leakage, loss, damage, or unauthorized copying of your Personal Information in Our system.</p>
                            
        <p>We will not share Personal Information with third-parties not covered by this Policy unless a consent form has been furnished to Us by YOU, and they shall conform with the Data Privacy Act of 2012 (the “DPA”) and confidentiality requirements.</p>
                            
        <p>Data Protection Measures. We have put in place physical, electronic and managerial procedures designed to help prevent unauthorized access, to maintain data security, and to use correctly the information we collect online. These safeguards vary based on the sensitivity of the information that we collect and store.</p>
                            
        <p>Third-party websites. We may, at times, provide links to third-party websites, or have advertisements with links to third-party sites. These links are provided as a service to You and We do not provide any personal data to these websites or advertisers.</p>
                            
        <p>Since We do not provide any data to these websites or advertisers, and We are not connected to them in any way, We are not responsible for their privacy practices, and our Privacy Policy does not apply to them. These sites have their own privacy policies, which you should review as well.</p>  
                            
        <p>Social Media Listening. We may use social media listening tools that monitor keywords or hashtags used in Your public social media posts for the purpose of monitoring Our reputation, feedback, tracking and resolving issues, detecting fraud or managing crisis.</p>


        <p>Changes in the Privacy Policy. We may revise this Policy if it’s required, or when dictated by the National Privacy Commission or any amendment to the DPA.</p>
                            
        <p>You will be informed of any changes made to this Policy as soon as We post it here on Our Services, and said changes will be effective immediately. To make sure You are always informed, We encourage you to check for updates every now and then.</p>
                            
        <p>Don’t worry, if We make any changes, the changes will not be retroactively applied, and it will not change how We handle Personal Information previously collected. For that, We will ask you for Your permission, unless it’s required by law that We change how we collect and process Personal Information.</p>
                            
        <p>Personal Information Controller. ITSLOGIC FREIGHT INC. is the Personal Information Controller under the DPA, which means that We determine what purposes personal information We hold will be used for. It may also be that your personal data is disclosed to third parties pursuant to a data sharing agreement. In which case, such third parties are also the Personal Information Controllers of your personal information</p>
                            
        <p>Rights of the Data Subject. Your data privacy rights under the DPA are the following: Right to access Your Personal Information</p>
        <p>Right to make corrections to Your Personal Information</p>
        <p>Right to object to the processing of Your Personal Information</p>
                            
        <p>Right to erasure or blocking of Your Personal Information</p>
                            
        <p>Right to be informed of the existence of processing of Personal Information</p>
                            
        <p>Right to damage</p>

        <p>Right to lodge a complaint before the NPC</p>
                            
        <p>Contact US:</p>
                            
        <p>How can you access, correct, and update personal data we have about you?</p>
                            
        <p>If You’d like to exercise Your data privacy rights or should You have any inquiries or feedback on this Policy, or any complaints to Us, you may reach Us through the following:</p>
                            
        <p>Our “Contact Us” button in our websites: itslogic.ph  </p>
                            
        <p>Through a written letter or an email to our Data Protection Officer (DPO) You can send a letter to:
        Data Protection Officer</p>
                            
        <p>ITSLOGIC FREIGHT INC.</p>
                            
        <p>2 Fokker St. Airmen’s Village, Pulang lupa Dos, Las Pinas City</p>
                            
        {/* <p>Office: 8658-3897</p> */}
        <p>Office: 8876-1499</p>
        <p>Email address: info@itslogic.ph</p>


        <p>You can also file a complaint with the:</p>
                            
        <p>National Privacy Commission (NPC).</p>
                            
        <p>For further details, please refer to NPC’s website: https://privacy.gov.ph/mechanics-for-complaints/</p>
                            
        <p>Please note that any request for correction, erasure and/or objection to 
            process your personal data is subject to applicable laws and/or the DPA, 
            its IRR and other issuances of the NPC. We may charge you a minimal fee to 
            cover for the cost of verifying a request for information and locating, 
            retrieving, reviewing and copying any material requested</p>
                            
        <p>Contact Number : 63-2-234-22-28</p>
                            
        <p>You are encouraged to keep Your Personal Information submitted to Us during registration current and updated.</p>
                            
        <p>You acknowledge and agree that ITSLOGIC reserves the right to disclose Your Personal Information to any legal, regulatory, government agency, law enforcement or authorities, as may be required by law, or if We have reasonable grounds to believe that disclosure of Your Personal Information is necessary for the purpose of meeting any obligations, requirements or arrangements whether voluntary or mandatory as a result of cooperating with an order, and investigation.</p>
                            
        <p>To the extent permissible by law, You agree not to take any action and /or waive Your right to take any action against ITSLOGIC for the disclosure of Your Personal Information in the above circumstances</p>

        <p>This consent and authorization remain valid and subsisting for a limited period consistent with the purposes above or until otherwise revoked or cancelled in writing. You may inform ITSLOGIC of the specific personal information you do not want to be processed beyond the requested purpose. ITSLOGIC will respect your request insofar as it is feasible to fulfill the purpose for which the personal information was collected</p>
        </div>
    </div>);
};

export default Privacy;