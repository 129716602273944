import LANDING_PAGE_BACKGROUND from '../../assets/img/landing-page/bg-image.png';
import LOGO_WHITE from '../../assets/img/logo/logo_white.png';
import AIR from '../../assets/img/landing-page/air.png';
import LAND from '../../assets/img/landing-page/land.png';
import SEA from '../../assets/img/landing-page/sea.png';
import CHECK from '../../assets/img/landing-page/check.png';
import PARTNER_1 from '../../assets/img/partners/partner1.jpg';
import PARTNER_2 from '../../assets/img/partners/partner2.jpg';
import PARTNER_3 from '../../assets/img/partners/partner3.jpg';
import PARTNER_4 from '../../assets/img/partners/partner4.jpg';
import PARTNER_5 from '../../assets/img/partners/partner5.jpg';
import PARTNER_6 from '../../assets/img/partners/partner6.jpg';
import PARTNER_7 from '../../assets/img/partners/partner7.jpg';

export const BRAND_NAME = 'ITSLogic';

export const BRAND_IMAGES = {
  LANDING_PAGE_BACKGROUND,
  LOGO_WHITE,
  CHECK,
};

export const PARTNERS_LOGO = [
  PARTNER_1,
  PARTNER_2,
  PARTNER_3,
  PARTNER_4,
  PARTNER_5,
  PARTNER_6,
  PARTNER_7,
];

export const SERVICES = [
  {
    title: 'Land',
    desc: "ITSLogic delivers commitment worldwide with your shipping needs in land and cargo. Whether it's documents, cargos, or shipments.",
    icon: LAND,
  },
  {
    title: 'Sea',
    desc: 'Our dedicated team promises to deliver your sea cargo and freight services on schedule.',
    icon: SEA,
  },
  {
    title: 'Air',
    desc: 'We are continuously creating a better and more efficient service by establishing local and global connections to boost our capabilities in handling your air freight and cargo solutions',
    icon: AIR,
  },
];

export const COMMODITIES = [
  [
    {
      label: 'GENERAL CARGOES',
      bgImage: require('../../assets/img/commodities/GeneralCargoes.png'),
    },
    {
      label: 'PERSONAL EFFECTS',
      bgImage: require('../../assets/img/commodities/Personal-effects.webp'),
    },
    {
      label: 'FOOD PRODUCTS',
      bgImage: require('../../assets/img/commodities/fresh.jpg'),
    },
  ],
  [
    {
      label: 'LIVE ANIMALS',
      bgImage: require('../../assets/img/commodities/Live-animals.png'),
    },
    {
      label: 'PROJECT CARGOES',
      bgImage: require('../../assets/img/commodities/project-cargo.jpg'),
    },
    {
      label: 'DANGEROUS GOODS',
      bgImage: require('../../assets/img/commodities/dangerous-good.jpg'),
    },
  ],
  [
    {
      label: 'UNACOMPANIED BAGGAGE',
      bgImage: require('../../assets/img/commodities/unaccompanied-baggage.jpg'),
    },
    {
      label: 'ELECTRONICS',
      bgImage: require('../../assets/img/commodities/Electronics.jpg'),
    },
    {
      label: 'ENGINE',
      bgImage: require('../../assets/img/commodities/engines.jpg'),
    },
  ],
  [
    {
      label: 'SPARE PARTS',
      bgImage: require('../../assets/img/commodities/spare-part.jpeg'),
    },
    {
      label: 'VEHICLES, MOTORCYCLES',
      bgImage: require('../../assets/img/commodities/VehiclesMotorcycles.jpg'),
    },
    {
      label: 'PERISHABLE',
      bgImage: require('../../assets/img/commodities/perishable.jpg'),
    },
  ],
  [
    {
      label: 'HUMAN REMAINS',
      bgImage: require('../../assets/img/commodities/HumanRemains.webp'),
    },
    {
      label: 'OVERSIZED SHIPMENTS',
      bgImage: require('../../assets/img/commodities/oversized.jpg'),
    },
    {
      label: 'OTHERS',
      bgImage: require('../../assets/img/commodities/HouseholdGoods.jpg'),
    },
  ],
];

export const DOMESTIC = [
  {
    destination: 'CEB',
    minimumRate: 500,
    ratePerKilo: 55,
  },
  {
    destination: 'BCD',
    minimumRate: 500,
    ratePerKilo: 55,
  },
  {
    destination: 'ILO',
    minimumRate: 500,
    ratePerKilo: 55,
  },
  {
    destination: 'TAC',
    minimumRate: 500,
    ratePerKilo: 55,
  },
  {
    destination: 'PPS',
    minimumRate: 550,
    ratePerKilo: 60,
  },
  {
    destination: 'TAG',
    minimumRate: 500,
    ratePerKilo: 55,
  },
  {
    destination: 'CGY',
    minimumRate: 550,
    ratePerKilo: 60,
  },
  {
    destination: 'DVO',
    minimumRate: 550,
    ratePerKilo: 60,
  },
  {
    destination: 'GES',
    minimumRate: 550,
    ratePerKilo: 60,
  },
  {
    destination: 'ZAM',
    minimumRate: 550,
    ratePerKilo: 60,
  },
];

export const INTERNATIONAL = [
  {
    destination: 'USA',
    minimumRate: 550,
    ratePerKilo: 60,
  },
  {
    destination: 'CAN',
    minimumRate: 550,
    ratePerKilo: 60,
  },
  {
    destination: 'HK',
    minimumRate: 550,
    ratePerKilo: 60,
  },
];
