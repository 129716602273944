import React from 'react';

const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <div className="copyright">
      <p>© {year} ITSLOGIC FREIGHT All Rights Reserved.</p>
    </div>
  );
};

export default Copyright;
