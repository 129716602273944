import { CssBaseline, Grid, TextField, Typography, Select, MenuItem, TextareaAutosize, Button, Snackbar, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addLead } from '../../common/api';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: '100vh',
        minHeight: 700,
    },
    topContainer: {
        height: '25vh',
        minHeight: 120,
        backgroundColor: '#191919',
        padding: '10px'
    },
    botContainer: {
        height: '70vh',
        padding: '20px',
        position: 'relative'
    },
    inputGroup: {
        width: '50vw',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        }
    },
    btnContainer: {
        width: '50vw',
        marginTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            marginBottom: '2rem'
        }
    },
    btn: {
        background: '#191919',
        opacity: 1,
        color: 'white',
        border: 'none',
        padding: '5px 30px',
        width: '182px',
        '&:hover':{
            background: '#191919',
            opacity: 0.5,
            border: 'none',
        },
    },
    thankyouContainer: {
        width: 700,
        padding: '20px',
        borderTop: '#9A7F3E 11px solid',
        position: 'absolute',
        top: -80,
        [theme.breakpoints.down('sm')]: {
            width: '90vw'
        }
    }
}))

const LeadFormThankyou = () => {

    const [countDown, setCountDown] = useState(10);

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {

        if(countDown === 0){
            history.push('/')
        }

        if(countDown > 0){
            const interval = setInterval(() => {
                setCountDown(countDown - 1)
            }, 1000)

            return () => clearInterval(interval)
        }
    }, [countDown])

  return (
      <React.Fragment>
          <CssBaseline />
            <Grid className={classes.mainContainer} container direction='column' justify='flex-start' alignItems='center'>
                <Grid className={classes.topContainer} item container justify='center' alignItems='center' direction='column'>
                        
                </Grid>
                <Grid className={classes.botContainer} item container justify='flex-start' alignItems='center' direction='column'>
                    <Grid item component={Paper} elevation={2} spacing={3} container className={classes.thankyouContainer} justify='center' alignItems='flex-start' direction='column'>
                        <Grid item>
                            <Typography variant='h4' style={{fontWeight: 'bold'}}>Thank You</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='title'>Thank you for cantacting us. We will be in touch with you shortly.</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='title'>Meanwhile you can follow us on <a href='https://www.facebook.com/Itslogic.ph' target='_blank' style={{color: 'blue'}}>@facebook</a> <a href='https://www.linkedin.com/company/itslogicph' target='_blank' style={{color: 'blue'}}>@Linkedin</a> for udpates.</Typography>
                        </Grid>
                        <Grid item container justifyContent='center' alignItems='center'>
                            <Typography variant='caption' color='textSecondary'>Redirecting {countDown}...</Typography>
                        </Grid>
                        <Grid item container justifyContent='center'>
                            <Button className={classes.btn} onClick={() => history.push('/')}>OK</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
      </React.Fragment>
  )
}

export default LeadFormThankyou;