import React from 'react';
import { useStyles } from '../../blocks/estimate-cost/Styles';
import { Typography } from '@mui/material';
import EstimateCostButton from '../button/EstimateCostButton';
import CloseIcon from '@mui/icons-material/Close';

const DisclaimerModal = ({ setIsOpen }) => {
  const classes = useStyles();

  return (
    <div className={classes.modalBg}>
      <div className={classes.modal}>
        <CloseIcon
          sx={{
            position: 'absolute',
            right: 0,
            cursor: 'pointer',
            margin: '10px',
          }}
          onClick={() => setIsOpen(false)}
        />
        <div className={classes.modalContent}>
          <Typography variant="h5" sx={{ fontWeight: '800', padding: '20px' }}>
            DISCLAIMER
          </Typography>
          <Typography sx={{ color: 'black', padding: '10px' }}>
            Please be aware that the rate listed below is not yet official. The
            exact rate will depend on the greater of the actual weight or
            dimension. For 5kg and up, we provide FREE pick-up in{' '}
            <strong>Metro Manila</strong>. Please let us know if you accept our
            rate and policy so we can arrange for the pick-up of your package.
            Thank You!!
          </Typography>
          <EstimateCostButton
            label="I AGREE"
            onClick={() => setIsOpen(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default DisclaimerModal;
