import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const MainContainer = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  paddingBottom: '50px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const CommodityCard = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'bgImage',
})(({ theme, bgImage }) => ({
  backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${bgImage})`,
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '550px',
  width: '100%',
  margin: '0 25px',
  '&:hover': {
    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(178,161,118,0.6)), url(${bgImage})`,
  },
}));
