import React, { Fragment, useEffect } from 'react';
import { MetaTags } from 'react-meta-tags';
import EstimateCost from '../blocks/estimate-cost/EstimateCost';
import Footer from '../blocks/footer/Footer';
import Header from '../blocks/header/Header';
import Loading from '../blocks/loading/Loading';
import PageTitleCommon from '../blocks/page-title/PageTitleCommon';

import shippingBg from '../assets/img/bg/shipping-bg.jpg';
import OtherServices from '../blocks/why-us/OtherServices';

const ShippingRate = () => {
  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>Shipping Rate | ITSLOGIC Freight Forwarding</title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon bg={shippingBg} title="Shipping Rate" />

        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="wrapper">
              <h4 style={{ textAlign: 'center' }}>Estimate Shipping Cost</h4>
              <EstimateCost />
            </div>
          </div>
        </section>
        <div className="bg-black py-5 mt-5">
          <OtherServices />
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ShippingRate;
