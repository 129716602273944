import React, { useEffect } from 'react'
import error from '../../assets/icons/svg/error.svg'
import success from '../../assets/icons/svg/success.svg'
import closed from '../../assets/icons/svg/close.svg'
import { Snackbar, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    alert: {
        backgroundColor: props => props.type === 'error' ? '#ef5350' : '#4caf50',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        width: '30vw',
        [theme.breakpoints.down('sm')]: {
            width: '70vw'
        }
    }
}))

const SnackBar = ({alert, setAlert}) => {

    const classes = useStyles({...alert});

    const Icon = () => {
        if(alert.type === 'error'){
            return <img src={error} height={20} width={20} />
        }

        if(alert.type === 'success'){
            return <img src={success} height={20} width={20} />
        }
    }

    const handleClose = (e, reason) => {
        if(reason === 'clickaway'){
            return;
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setAlert({...alert, open: false})
        }, 3000)
    },[alert.open]);

  return (
    <Snackbar open={alert.open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center',}}>
        <Grid container justify='start' alignItems='center' spacing={1} className={classes.alert}>
            <Grid item>
                <Icon />
            </Grid>
            <Grid item>
                <Typography variant='caption'>{alert.message}</Typography>
            </Grid>
        </Grid>
    </Snackbar>
  )
}

export default SnackBar;