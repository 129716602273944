import { makeStyles, createTheme } from '@material-ui/core';

export const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            border: '1px solid gray',
          },
          '&.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#9a7f3e',
            },
          },
        },
      },
    },
  },
});

export const useStyles = makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formGrid: {
    padding: '20px 50px',
    [theme.breakpoints.down('md')]: {
      padding: '30px',
    },
  },
  form: {
    width: '97%',
  },
  modalBg: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.5)',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    height: '100%',
    width: '100%',
    zIndex: '100',
  },
  modal: {
    position: 'fixed',
    height: '400px',
    width: '650px',
    top: '20%',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    marginTop: '20px',
    textAlign: 'center',
  },
}));
