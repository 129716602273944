import React, { useState } from 'react';
import { Grid, TextField, Select, MenuItem, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useStyles, theme } from './Styles';
import DisclaimerModal from '../../components/modal/DisclaimerModal';
import EstimateCostButton from '../../components/button/EstimateCostButton';
import { DOMESTIC, INTERNATIONAL } from '../../common/utils/constant';
import InfoIcon from '@mui/icons-material/Info';
import {
  calculateExcessWeight,
  insertCommas,
} from '../../common/utils/helpers';

const EstimateCost = () => {
  const classes = useStyles();
  const [data, setData] = useState({
    mode: '',
    from: '',
    to: '',
    weight: '',
    length: '',
    width: '',
    height: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [rate, setRate] = useState(0);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === 'from') {
      setData({ ...data, [name]: value, to: '' });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let details = {};
    if (data.mode === 'Domestic') {
      DOMESTIC.map((item) => {
        if (
          item.destination === data[`${data.from === 'MNL' ? 'to' : 'from'}`]
        ) {
          details = item;
        }
      });
      const excessWeightRate = calculateExcessWeight(
        details.ratePerKilo,
        data.weight
      );
      const rate = excessWeightRate + details.minimumRate;
      setRate(rate);
    } else if (data.mode === 'International') {
      INTERNATIONAL.map((item) => {
        if (
          item.destination === data[`${data.from === 'MNL' ? 'to' : 'from'}`]
        ) {
          details = item;
        }
      });
      const rate = details.minimumRate + Number(data.weight);
      setRate(rate);
    }
  };

  const conditionalMenuItems = (to) => {
    if (data.mode === 'Domestic') {
      return DOMESTIC.map((item, index) => (
        <MenuItem
          key={index}
          value={item.destination}
          disabled={data.from !== 'MNL' && to ? true : false}
        >
          {item.destination}
        </MenuItem>
      ));
    } else if (data.mode === 'International') {
      return INTERNATIONAL.map((item, index) => (
        <MenuItem
          key={index}
          value={item.destination}
          disabled={data.from !== 'MNL' && to ? true : false}
        >
          {item.destination}
        </MenuItem>
      ));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      {isOpen && <DisclaimerModal setIsOpen={setIsOpen} />}
      <form
        className={classes.formContainer}
        onSubmit={onSubmit}
        autoComplete="off"
      >
        <Grid container alignItems="center" className={classes.formGrid}>
          <Grid item xs={12} md={4}>
            <Select
              value={data.mode}
              name="mode"
              label="Select Transport Mode"
              onChange={handleChange}
              displayEmpty
              required
              className={classes.form}
              sx={{ fontSize: '16px' }}
            >
              <MenuItem value="" disabled selected>
                <em>Select Transport Mode</em>
              </MenuItem>
              <MenuItem value="Domestic">Domestic</MenuItem>
              <MenuItem value="International" disabled>
                International
              </MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={4} sx={{ marginTop: { xs: '20px', md: '0' } }}>
            <Select
              value={data.from}
              name="from"
              label="From"
              onChange={handleChange}
              displayEmpty
              required
              className={classes.form}
              sx={{ fontSize: '16px' }}
              disabled={data.mode === '' ? true : false}
            >
              <MenuItem value="" disabled selected>
                <em>From</em>
              </MenuItem>
              <MenuItem value="MNL">MNL</MenuItem>
              {conditionalMenuItems(false)}
            </Select>
          </Grid>
          <Grid item xs={12} md={4} sx={{ marginTop: { xs: '20px', md: '0' } }}>
            <Select
              value={data.to}
              name="to"
              label="To"
              onChange={handleChange}
              displayEmpty
              required
              className={classes.form}
              sx={{ fontSize: '16px' }}
              disabled={data.from === '' ? true : false}
            >
              <MenuItem value="" disabled selected>
                <em>To</em>
              </MenuItem>
              <MenuItem
                value="MNL"
                disabled={data.from === 'MNL' ? true : false}
              >
                MNL
              </MenuItem>
              {conditionalMenuItems(true)}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          className={classes.formGrid}
        >
          <Grid item xs={12} md={3}>
            <TextField
              placeholder="Weight (kg)"
              name="weight"
              value={data.weight}
              onChange={handleChange}
              required
              className={classes.form}
              inputProps={{ style: { fontSize: '16px' } }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ marginTop: { xs: '20px', md: '0' } }}>
            <TextField
              placeholder="Length (cm)"
              name="length"
              value={data.length}
              onChange={handleChange}
              required
              className={classes.form}
              inputProps={{ style: { fontSize: '16px' } }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ marginTop: { xs: '20px', md: '0' } }}>
            <TextField
              placeholder="Width (cm)"
              name="width"
              value={data.width}
              onChange={handleChange}
              required
              className={classes.form}
              inputProps={{ style: { fontSize: '16px' } }}
            />
          </Grid>
          <Grid item xs={12} md={3} sx={{ marginTop: { xs: '20px', md: '0' } }}>
            <TextField
              placeholder="Height (cm)"
              name="height"
              value={data.height}
              onChange={handleChange}
              required
              className={classes.form}
              inputProps={{ style: { fontSize: '16px' } }}
            />
          </Grid>
        </Grid>
        <EstimateCostButton label="GET AN ESTIMATE" />
      </form>
      <Typography
        sx={{ padding: '30px 50px', color: 'black', fontWeight: 700 }}
      >
        Freight Estimated:{' '}
        <span style={{ color: 'red' }}>₱ {insertCommas(rate.toFixed(2))}</span>{' '}
        <InfoIcon
          sx={{ height: '20px', cursor: 'pointer' }}
          onClick={() => setIsOpen(true)}
        />
      </Typography>
    </ThemeProvider>
  );
};

export default EstimateCost;
