import React from 'react';

import WhyUsPdf from './WhyUsPdf';
import OtherServicesItems from '../../data/service-inside/otherServicesItems.json';

const OtherServices = () => {
    return (
        <div className="wrapper">
            <div className="content">
                <div className="clearfix">
                        <div className="other-services-grid">
                            {OtherServicesItems.map((item)=> {
                                return (
                                <div key={item.id} className="px-1">
                                    <p className="text-white p-medium bold">{item.title}</p>
                                    <p className="text-white p-small">{item.description}</p>
                                </div>
                                )
                            })}
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default OtherServices;
