import {
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Select,
  MenuItem,
  TextareaAutosize,
  Button,
  Snackbar,
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { addLead } from '../../common/api';
import Loader from '../loader/Loader';
import { onlyNumberKey } from '../../common/utils/helpers';
import SnackBar from '../alertSnackbar/SnackBar';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    minHeight: 700,
  },
  topContainer: {
    backgroundColor: '#191919',
    padding: '20px',
  },
  logo: {
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      objectFit: 'contain',
      height: '80px',
      width: '80px',
    },
  },
  botContainer: {
    height: '70vh',
    padding: '20px',
  },
  inputGroup: {
    width: '50vw',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
    },
  },
  btnContainer: {
    width: '50vw',
    marginTop: '20px',
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      marginBottom: '2rem',
    },
  },
  btn: {
    background: '#191919',
    opacity: 1,
    color: 'white',
    width: '25vw',
    marginBottom: '50px',
    border: 'none',
    '&:hover': {
      background: '#191919',
      opacity: 0.5,
      border: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50vw',
    },
  },
}));

const LeadForm = () => {
  const classes = useStyles();
  const history = useHistory();

  const [isThankyou, setIsThankyou] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: '',
    message: '',
  });

  const initialState = {
    contact_name: '',
    partner_name: '',
    phone: '',
    email_from: '',
    type: 'lead',
    service: '',
    name: '',
    crm_message: '',
  };

  const [data, setData] = useState(initialState);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({
      ...data,
      [name]: value,
    });
  };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try{
            setLoading(true);
            const res = await addLead(data);
            
            if(res?.result?.success){
                setLoading(false);
                setData(initialState);
                setAlert({open: true, type: 'success', message: 'Details successfully submitted!'})
                history.push('/lead-form-thankyou')
            }

      if (res?.result?.success) {
        setLoading(false);
        setData(initialState);
        setAlert({
          open: true,
          type: 'success',
          message: 'Details successfully submitted!',
        });
        history.push('/lead-form-thankyou');
      }

      if (res?.error) {
        throw res.error.message;
      }
    } catch (err) {
      setLoading(false);
      setAlert({ open: true, type: 'error', message: err?.message ?? err });
      console.log(err);
    }
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <SnackBar alert={alert} setAlert={setAlert} />
      <Loader loading={loading} />
      <Grid
        className={classes.mainContainer}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Grid
          className={classes.topContainer}
          item
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <img
            src={require('../../assets/img/logo/logo_white.png')}
            alt="logo"
            loading="lazy"
            onClick={() => history.push('/')}
            className={classes.logo}
          />
          <Typography variant="subtitle1" style={{ color: 'white' }}>
            Learn more by providing your info below.
          </Typography>
        </Grid>
        <Grid
          className={classes.botContainer}
          item
          container
          justifyContent="flex-start"
          alignItems="center"
          direction="column"
        >
          <form onSubmit={handleSubmit} autoComplete="off">
            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>Name</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  onChange={handleChange}
                  name="contact_name"
                  value={data?.contact_name}
                  required
                  type="text"
                  placeholder="Enter your name"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>Company</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  onChange={handleChange}
                  name="partner_name"
                  value={data?.partner_name}
                  required
                  type="text"
                  placeholder="Enter your company"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>
                  Contact No.
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  onChange={handleChange}
                  onKeyPress={(e) => onlyNumberKey(e)}
                  name="phone"
                  value={data?.phone}
                  required
                  type="text"
                  inputProps={{ maxLength: 11 }}
                  placeholder="Enter your contact no."
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>Email</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  onChange={handleChange}
                  name="email_from"
                  value={data?.email_from}
                  required
                  type="email"
                  placeholder="Enter your email"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>
                  Service Type
                </Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-autowidth"
                  value={data?.service}
                  onChange={handleChange}
                  name="service"
                  label="Choose your type"
                  fullWidth
                  displayEmpty
                  required
                >
                  <MenuItem value="" disabled selected>
                    <em>Choose your type</em>
                  </MenuItem>
                  <MenuItem value="Import">Import</MenuItem>
                  <MenuItem value="International">Export</MenuItem>
                  <MenuItem value="Domestic">Domestic delivery</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>Subject</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextField
                  onChange={handleChange}
                  name="name"
                  value={data?.name}
                  required
                  type="text"
                  placeholder="Enter your subject"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              spacing={2}
              className={classes.inputGroup}
              alignItems="center"
            >
              <Grid item xs={12} md={3}>
                <Typography style={{ fontWeight: 'bold' }}>Message</Typography>
              </Grid>
              <Grid item xs={12} md={9}>
                <TextareaAutosize
                  style={{
                    border: '1px #DCDCDC solid',
                    borderRadius: '5px',
                    padding: '10px',
                    minHeight: '100px',
                    maxHeight: '100px',
                  }}
                  onChange={handleChange}
                  name="crm_message"
                  value={data?.crm_message}
                  required
                  maxRows={3}
                  placeholder="Write your message"
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid
              className={classes.btnContainer}
              container
              justifyContent="center"
            >
              <Button type="submit" className={classes.btn} variant="contained">
                Submit
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default LeadForm;
