import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';

import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';

import PageTitleCommon from '../blocks/page-title/PageTitleCommon';
import ServiceInsideSwiper from '../blocks/service-inside/ServiceInsideSwiper';
import ValueAddedServiceSwiper from '../blocks/service-inside/ValueAddedServiceSwiper';
import ServiceInsideWidget from '../blocks/service-inside/ServiceInsideWidget';
import partnershipBg from '../assets/img/bg/service-partnershipBg.jpg';
import ContactLogisticPartnershipSwiper from '../blocks/service-inside/ContactLogisticPartnershipSwiper';
import OtherServices from '../blocks/why-us/OtherServices';

const ContactLogisticPartnership = () => {
  useEffect(() => {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_service');

    return () => {
      document.body.classList.remove('single');
      document.body.classList.remove('single-adveits_service');
    };
  }, []);

  return (
    <Fragment>
      <MetaTags>
        <meta charSet="UTF-8" />
        <title>
          Contact Logistic / Partnership | ITSLOGIC Freight Forwarding
        </title>

        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="index, follow, noodp" />
        <meta name="googlebot" content="index, follow" />
        <meta name="google" content="notranslate" />
        <meta name="format-detection" content="telephone=no" />
      </MetaTags>

      <Loading />

      <Header logoColor="dark" />

      <main id="main" className="site-main">
        <PageTitleCommon
          bg={partnershipBg}
          title="Contact Logistic / Partnership"
        />

        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <div className="content">
              <div id="single">
                <div className="row gutter-width-md single-content">
                  <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                    <ContactLogisticPartnershipSwiper />
                  </div>

                  <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12">
                    <aside id="aside" className="widget-area style-default">
                      <div id="service" className="widget_service">
                        {/* <ServiceInsideWidget /> */}
                        <div className="description style clearfix spacer m-top-lg">
                          <ul>
                            <li>
                              <h5>For Partnership</h5>
                              <p style={{ marginBottom: '20px' }}>
                                Contact us at:
                              </p>
                              <p
                                className="p-small"
                                style={{ marginBottom: '10px' }}
                              >
                                info@itslogic.ph
                              </p>
                              {/* <p className="p-small">Tel. (02) 8658-3897</p> */}
                              <p className="p-small">Tel. (02) 8876-1499</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="bg-black py-5 mt-5">
          <OtherServices />
        </div>
      </main>

      <Footer />
    </Fragment>
  );
};

export default ContactLogisticPartnership;
